import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getTableViewsList = createAsyncThunk(
  'tableViews/getTableViewsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/table-views`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createTableView = createAsyncThunk(
  'tableViews/createTableView',
  async (tableViewData, { rejectWithValue }) => {
    const { name, type, tableData: table_data } = tableViewData;

    try {
      const response = await axiosInstance.post(`/table-views`, { name, type, table_data });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateTableView = createAsyncThunk(
  'tableViews/updateTableView',
  async (tableViewData, { rejectWithValue }) => {
    const { name, type, uuid, table_data } = tableViewData;

    try {
      const response = await axiosInstance.put(`/table-views/${uuid}`, {
        name,
        type,
        table_data,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteTableView = createAsyncThunk(
  'tableViews/deleteTableView',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/table-views/${uuid}`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setDefaultTableView = createAsyncThunk(
  'tableViews/setDefaultTableView',
  async ({ default_view }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.post(`/${domainID}/default-view`, {
        default_view,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getDefaultTableView = createAsyncThunk(
  'tableViews/getDefaultTableView',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.get(`/${domainID}/default-view`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
