export const validateURLWithISOFormat = (url) => {
  // Regular expression to match a URL with ISO format
  const regex = /^([a-z]{2,}):\/\/[^\s/$.?#]/;
  return regex.test(url);
};

export const isValidUrl = (string) => {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
};

/**
 * For cases where we need to get parameters, but for some reason we cannot use the useParams hook.
 */
export const getSearchParams = (url) => {
  if (!url) {
    url = window.location.search;
  }

  const queryString = url.substring(1);
  const searchParams = new URLSearchParams(queryString);
  const params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};

export const setFilterParams = ({ items: filters, linkOperator: joiner }) => {
  const serializedFilters = encodeURIComponent(JSON.stringify(filters));
  const serializedFiltersJoiner = encodeURIComponent(JSON.stringify(joiner));

  const { pathname } = window.location;
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('filters', serializedFilters);
  searchParams.set('filtersJoiner', serializedFiltersJoiner);

  window.history.pushState({}, '', `${pathname}?${searchParams.toString()}`);
};
